import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ResetFailedForm from './style'
import { Images } from '~data'
export default function ResetFailed(){
return(
<ResetFailedForm backgroundColor="#f3f4f6">
  <Container className="position-static">
    <Row className="align-items-center justify-content-center position-static">
      <Col xs="12" className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-xs-10">
          <ResetFailedForm.Box>
          <ResetFailedForm.Title as="h2">Password Reset Failed</ResetFailedForm.Title>
          <ResetFailedForm.Text as="p">Please Try Again Using the Link From Your Email.</ResetFailedForm.Text>
          </ResetFailedForm.Box>
      </Col>
    </Row>
  </Container>
</ResetFailedForm>
)
}